import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function HeaderIndex() {

    const [search, setSearch] = useState('');
    const [logstatus, setLogstatus] = useState(() => {
        const st = localStorage.getItem("login");
        return st || ""
    });

    function handleClick() {
        console.log(search);
        window.location.href = "/specialists/" + search;
    }
    return (
        <React.Fragment>
            <div class="col-sm-12 px-0">
                <nav class="navbar navbar-expand-lg">
                    <div class="container">
                        <a class="navbar-brand d-flex align-items-center d-lg-block" href="/">
                            <img src="/assets/images/logo (1).png" alt="logo" />
                            {/* <p>The FRCS (ORL-HNS) Revision Aid</p> */}
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="offcanvas offcanvas-start" id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                                    aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navbar-nav mx-auto">
                                <li class="nav-item" style={{ marginTop: 5 }}>
                                        <Link class="nav-link" aria-current="page" to="/">Home</Link>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <Link class="nav-link" to="/about-us/">About Us</Link>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <Link class="nav-link" to="/contact-us">Contact Us</Link>
                                    </li>
                                    {/* <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/medical-student">About the Exam</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/preparing-exam">Preparing for the Exam</a>
                                    </li>
                                    <li class="nav-item" style={{ marginTop: 5 }}>
                                        <a class="nav-link" href="/subscribe">Subscriptions</a>
                                    </li> */}
                                    {logstatus === "" ?
                                        (
                                            <React.Fragment>
                                                <li class="nav-item" style={{marginTop:5}}>
                                                    <a class="nav-link" href="/trial-quiz">Free Trial</a>
                                                </li>
                                                <li class="nav-item" style={{marginTop:5}}>
                                                    <a class="nav-link login" href="/login">Login</a>
                                                </li>
                                                <li class="nav-item" style={{marginTop:5}}>
                                                    <a class="nav-link register" href="/register">Register</a>
                                                </li>
                                            </React.Fragment>

                                        )

                                        :

                                        (<li class="nav-item">
                                            {localStorage.getItem('user') ==="user" ? <a class="nav-link login" href="/dashboard">My Dashboard</a>
                                            :<a class="nav-link login" href="/questioner_dashboard">My Dashboard</a>
                                            }
                                        </li>)
                                    }
                                </ul>
                                {/* <div class="d-flex" role="search">
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="bi bi-search"></i></span>
                                        <input class="form-control me-2" type="search" placeholder="Search"
                                            aria-label="Search" value={search} onChange={(search)=>{setSearch(search.target.value)}}/>
                                        <button type='button' className="nav-link login" style={{marginRight:0,}} onClick={()=>{handleClick()}}>Find</button>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default HeaderIndex;