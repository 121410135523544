import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from 'react-meta-tags';
function About() {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Metal Vepar - About us</title>
                <meta name="description" content="ENT MCQ: The online education company for practice tests, mock exams, and remedial analysis for FRCS (ORL-HNS).Achieving Excellence in Medical Exams" />
                <meta property="og:title" content="About us-ENT MCQ - Your Medical Exam Prep" />
                <meta property="keywords" content="frcs in medical, frcs mcq, Single Best Answer (SBA), examination preparation, FRCS training, medical revision, frcs exam, mcq bank app, frcs mcq, UK-based ENT surgeons, frcs ent,  FRCS curriculum, ENT FRCS exams, ent mcq, mcq ent, Frcs"/>
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    <h1>About ENT MCQ</h1>

                                </div>
                                {/* <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a> </li>
                                            <li class="breadcrumb-item active" aria-current="page">About ENT MCQ</li>
                                        </ol>
                                    </nav>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <h3 class="mb-4" style={{fontWeight:'bold'}}>About Platform</h3>
                        <p style={{ textAlign: 'justify' }}>Metal Vepar is a "Digital Platform" built exclusively to connect Metal Manufacturer and traders across India. Metal Vepar is a Digital platform mostly useful for the manufacturers and traders who are having business in mostly mild Steel, Stainless Steel, Aluminum, Brass, copper & others metals .

Metal Vepar will provide information about newest products; reach to new customers, new innovative theology & Metal knowledge. Metal vepar also provides market place which will expand the business, brand and products across India. Metal vepar is a platform which will take your products to all individual traders, and also bring on your fingertip about all required service providers to your business like machine maintenance, powder coating, anodizing, annealing, pickling, chrome plating, electro polishing, cutting, bending, beveling, PVDF coating, gas suppliers & also manpower skill development programmes.

Metal Vepar also provides information about metal related news and exhibitions across the globe. Metal Vepar also provides you firsthand information about Import & Export Business.</p>
                        <p class="fw-bold">Our unique features:</p>
                        <ul>
                            <li><p style={{ textAlign: 'justify' }}>For any product or service to all members on the platform and receive calls directly for further negotiation. This service is totally free of charge for all "Subscribed Members", and Metal Vepar does not indulge in any negotiations or sharing of any information regarding any deal.</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Which is an exclusive club for all Buyers on the app. Interested buyers can share their requirements which we will fulfil as a managed service. This service eliminates the need to make multiple postings and await responses. SB will act as agents and connect with all Sellers on its database to fulfil these requirements. We basically will act as Sourcing Agents. This service will entail charges.</p></li>
                            <li><p style={{ textAlign: 'justify' }}>For any company desiring to sell its Non-Moving Inventory at attractive prices. SB will hold Live Auctions every weekend and help recover capital blocked in Non-Moving Inventory. This is a chargeable service.</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Where we will present & digitally market your Company/Products/Brand to our members. This service will entail charges but benefit your Company/Brand through excellent visibility and recall.</p></li>
                            
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <img src="/assets/images/aboutus.svg" class="img-fluid" />
                    </div>
                    {/* <div class="col-sm-12">
                        <p style={{ textAlign: 'justify' }}>This website is by no means a recommendation as your only preparation method for your upcoming examination. We highly recommend you undertake prior theoretical learning, use multiple learning resources and use this website as an adjunct to apply theory into examination practice and consolidate your learning prior to sitting the examination. An important pearl to remember is that this examination is ‘Single Best Answer’, regardless of whether you think that none of the options are realistic, viable or appropriate – you HAVE to select an answer.</p>
                        <p style={{ textAlign: 'justify' }}>These questions have been designed and created based on the past experience of our highly successful team members, who all passed this examination recently on their first attempt/sitting. They have constructed these questions from scratch, based on the new ISCP Otolaryngology curriculum (August 2022) and align with the themes and learning objectives outlined and approve by the GMC, HEE and the JCST.</p>
                        <p style={{ textAlign: 'justify' }}>It is prudent to note that any resemblance of our questions to other available learning resources either published, printed or online, are purely coincidental. Our team regularly review and update questions to match real clinical practice and are all initially made based on the higher surgical training curriculum, we always appreciate feedback and suggestions for change, so please do reach out to us if you feel something can be improved.</p>
                        <p style={{ textAlign: 'justify' }}>Being the only dedicated practice question bank platform available for this examination, we feel we should give back as people contribute. We have therefore made a submission section where you will be invited in the near future to submit relevant and appropriate questions with answers. If approved to add to our question bank, you will be paid £5 per 5 questions accepted, by BACS bank transfer directly into your UK bank account.</p>
                    </div> */}
                </div>
            </div>
            <Footer />

        </React.Fragment>
    )
}

export default About;