import axios from "axios";
import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SpinnerLoad from "./UIComponents/SpinnerLoad";


function UserLightBox() {
    const [show, setShow] = useState(true);
    const [fname, setFname] = useState('');
    const [em, setEm] = useState('');
    const [job, setJob] = useState('');
    const [prof, setProf] = useState('United Kingdom');
    const [yr, setYr] = useState('January 2024');
    const [nameError, setNameError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [termsconditions, setTermsConditions] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [isSumit,setSumit] = useState(false);


    function handleClose() {
        setShow(!show);
    }

    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        setTermsConditions(e.target.checked);
    }

    function handleRegister() {
        if (!fname) {
            toast.error("Please enter First Name");
            setNameError(true)
        }
        else if (!isEmail(em)) {
            toast.error("Please enter valid Email");
            setEmError(true);
        }
        else if (!termsconditions) {
            toast.error("Please Accept Terms and Condition");
            setTermsError(true)
        }
        else if (!job) {
            toast.error("Please Accept Terms and Condition");
            setTermsError(true)
        }

        else {
            setSumit(true)
            const data = {
                name: fname,
                email: em,
                job: job,
                prof: prof,
                anti: yr,
                type: 'user'
            }
            axios.post('https://api.fab-g.com/api/launch-reg', data)
                .then((res) => {
                    const rdata = res.data;
                    if (rdata.status == 'success') {
                        // toast.success(rdata.msg);
                        toast.success("Thank you for submitting your pre-registration form for our website. We will be in touch very soon with early bird access and a discount code to use our question bank.")
                        setShow(false);

                    }
                    setSumit(false)
                })
        }
    }
    return (
        <React.Fragment>
            <ToastContainer />

            <div class="container-fluid">
                <div class="row">
                    <Header />
                    <Modal show={show} onHide={handleClose}
                        size={'lg'}
                    >

                        <Modal.Body>
                            <div style={{ position: 'absolute', paddingRight: 32, width: "100%" }}>
                                <span style={{ float: 'right', background: '#000', color: '#fff', borderRadius: 5, padding: 5, }} onClick={handleClose}>X</span>
                            </div>
                            {/* <p>Quiz Completed!</p> */}

                            <div class="col-sm-12 " style={{ background: '#188ccc' }}>
                                <img src="/assets/images/bg_lightbox.png" class="img-fluid" />
                            </div>
                            <div class="col-sm-12" style={{ padding: 10, }}>

                                <h1 style={{ fontWeight: 'bold', fontSize: 40, marginTop: 15, textAlign: 'left' }}>Exciting News - Launching Soon!</h1>
                                <p style={{ marginTop: 20, textAlign: 'justify' }}>We are very excited to announce that access to our  FRCS (ORL-HNS) exam specific, peer-reviewed and evidence based question bank will be available very soon. To receive an alert and early bird access with a special launch discount code, please complete the following pre-registration information:</p>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Full Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Full Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Job Title <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Job Title" value={job} onChange={(fname) => {
                                            setJob(fname.target.value)
                                            // setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Region of Training<span class="text-danger">*</span></label>
                                        <select class="form-select" value={prof} onChange={(prof) => {
                                            setProf(prof.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="International">International</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Anticipated Exam Sitting<span class="text-danger">*</span></label>
                                        <select class="form-select" value={yr} onChange={(yr) => {
                                            setYr(yr.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="January 2024/">January 2024</option>
                                            <option value="March 2024 (International)/">March 2024 (International)</option>
                                            <option value="July 2024">July 2024</option>
                                            <option value="Undecided">Undecided</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="/terms-conditions" class="text-main" >Terms & Conditions</a>
                                            <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                                        </label>
                                    </div>
                                </div>
                                {isSumit?<SpinnerLoad/> : <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>}
                            </div>

                        </Modal.Body>

                    </Modal>
                </div>
            </div>
            <div className="container py-4">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-6 text-center text-lg-end">
                        <h4>To become a Question Author</h4>
                    </div>
                    <div className="col-sm-6 text-center text-lg-start">
                        <button className="btn login text-white" type="button" style={{ backgroundColor: '#0d5c63' }}
                            onClick={() => {
                                window.location.href = "/author-launch";
                            }}
                        >Register Here</button>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default UserLightBox;