import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement, Title,);


function MyQuizzes() {

    //const id = localStorage.getItem('info');
    const [quiz, setQuiz] = useState([]);
    const [allQuiz, setAllQuiz] = useState([]);
    const [subinfo, setSubInfo] = useState([]);
    const [ans, setAns] = useState(0)
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [total, setTotal] = useState(0);
    const [title, setTitle] = useState('')
    const [show, setShow] = useState(false);
    // const filters = ["All",'Active Quiz','Open Quiz','Completed Quiz']

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };
    const data = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                label: 'Performance ' + title,
                data: [ans, wrong, unsen],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',

                ],
                borderWidth: 1,
            },
        ],
    };

    const handleClose = () => setShow(false);

    function fetchQuiz() {
        const id = localStorage.getItem('info');
        const sid = localStorage.getItem('subid')
        // console.log('https://entmcq.vertextechnosys.com/api/fetchQuizzes/'+sid+'--'+id)
        axios.get('https://api.fab-g.com/api/fetchQuizzes', { headers: { "Authorization": `Bearer ${id}` } })
            .then((res) => {
                const data = res.data;
                setQuiz(data);
                setAllQuiz(data);
                // console.log(data)
                //console.log(res)
            })
    }

    function quizMeta(id) {
        // const qid = localStorage.getItem('subid');
        // const uid = localStorage.getItem('info');
        // axios.get('https://api.fab-g.com/api/fetchSubjectQuiz/' + id,{headers:{"Authorization" : `Bearer ${uid}`}})
        //     .then((resp) => {
        //         console.log(resp.data);
        //         setShow(true)
        //         setSubInfo(resp.data);
        //         setAns(resp.data[0].ans)
        //         setUnsen(resp.data[0].unsen)
        //         setWrong(resp.data[0].wrong)
        //         setTotal(resp.data[0].total);
        //         setTitle(resp.data[0].subname)
        //         // console.log(resp.data);
        //     })

        const qid = id;
        const uid = localStorage.getItem('info');
        axios.get('https://api.fab-g.com/api/fetchQzQs/' + qid, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                // console.log(data);
                localStorage.setItem("qdata", JSON.stringify(data[0].quiz_details))
                localStorage.setItem("bank", JSON.stringify(data[1].bank));
                window.location.href = "/dashboard/review"
                // localStorage.setItem(data[0].quiz_details)
                // var bankdata = data[1].bank;
            })
    }

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    function handleObj(obj) {
        console.log(obj);
        setAns(obj.ans)
        setUnsen(obj.unsen)
        setWrong(obj.wrong)
        setTotal(obj.total);
        setTitle(obj.subname)
    }
    function handleClick(val) {
        // console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }

    function handleChange(type) {
        // alert(type);
        if (type == "All") {
            setQuiz(allQuiz)
        }
        else {
            var temp = allQuiz.filter((obj) => {
                if (obj.status === type)
                    return obj;
            })
            setQuiz(temp);
            console.log(temp);
        }
    }

    useEffect(() => {
        var tscode = localStorage.getItem('scode');
        if (tscode == "no") {
            window.location.href = "/no-subscription";
        }
        fetchQuiz();
    }, []);
    return (
        <React.Fragment>
            <Dashboard nm="Dashboard | My Quizzes" />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>Quiz Summery</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table class="table dashboard-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Correct</th>
                                <th>Incorrect</th>
                                <th>Unanswered</th>
                                <th># of Q's</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                subinfo.map((obj) => {
                                    return (
                                        <tr>
                                            <td>
                                                <p class="mb-2">{obj.subname}</p>

                                            </td>
                                            <td>{obj.ans}</td>
                                            <td>{obj.wrong}</td>
                                            <td>{obj.unsen}</td>
                                            <td>{obj.total}</td>
                                            <td>
                                                <button type="button"
                                                    class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>show</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                    <div class="card h-100 plain-card">
                        <div class="card-header">Performance</div>
                        <div class="card-body">
                            <Line data={data} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setShow(false)
                        // setReview(true)
                    }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <main>
                <div class="d-flex justify-content-between mb-3 filterbuttons">
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => handleChange('All')} defaultChecked />
                        <label class="btn btn-outline-primary  rounded" for="btnradio1">All</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autoComplete="off" onChange={() => handleChange('active')} />
                        <label class="btn btn-outline-warning mx-2 rounded" for="btnradio3">Active</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onChange={() => handleChange('completed')} />
                        <label class="btn btn-outline-success rounded" for="btnradio2">Completed</label>


                    </div>
                    {/* <a href="quizzes/archived.html" type="button" class="btn btn-light text-main">
                View Archieved Quizzess
            </a> */}
                </div>


                <div class="row">
                    {
                        quiz.map((obj) => {

                            return (<div class="col-sm-3 mb-3">
                                <div class="card quizz-box">
                                    <div class="card-body">
                                        <div class="text-end">
                                            <small>{obj.dt}</small>
                                        </div>
                                        <h6>{obj.qname}</h6>

                                        <div class="row g-2">
                                            <div class="col-sm-6">
                                                <div class="card card-body p-2 border-0 bg-success bg-opacity-10 text-success">
                                                    <h5>{obj.ans}</h5>
                                                    <p class="mb-0">Correct</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="card card-body p-2 border-0 bg-danger bg-opacity-10 text-danger">
                                                    <h5>{obj.wrong}</h5>
                                                    <p class="mb-0">Incorrect</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="card card-body p-2 border-0 bg-primary bg-opacity-10 text-primary">
                                                    <h5>{obj.unsen}</h5>
                                                    <p class="mb-0">Unanswered</p>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="card card-body p-2 border-0 bg-light">
                                                    <h5>{obj.nqs}</h5>
                                                    <p class="mb-0">No. of Q's</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="d-flex justify-content-between">
                                            {/* <button type="button" class="btn bg-warning bg-opacity-25 text-dark">
                                Archive
                            </button> */}
                                            {obj.status === 'completed' ? (
                                                <React.Fragment>
                                                    <button type="button" class="btn bg-success bg-opacity-10 text-success" >
                                                        Completed
                                                    </button>
                                                    <button type="button" class="btn bg-primary bg-opacity-10 text-primary" onClick={() => {
                                                        quizMeta(obj.id)
                                                    }}>
                                                        Summary
                                                    </button>
                                                </React.Fragment>
                                            ) : (<button type="button" class="btn bg-success bg-opacity-10 text-success" onClick={() => { handleClick(obj.id) }}>
                                                Continue
                                            </button>)}
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        })
                    }

                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}

export default MyQuizzes;