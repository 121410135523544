import axios from "axios";
import React, { useState,useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import { useParams } from "react-router-dom";

function AccountActivate() {
    const id = useParams();
    const [isLoading, setLoading] = useState(true);

    
    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        //setTermsConditions(e.target.checked);
        axios.get('https://api.fab-g.com/api/activated/'+id.id)
            .then((res)=>{
                setLoading(false);
            })
    }

    useEffect(()=>{
        handleCheck();
    },[])

    return (
        <React.Fragment>
            {isLoading ? <LoadingSpinner /> : (<React.Fragment>
                <ToastContainer />

                <div class="container-fluid">
                    <div class="row">
                        <Header />
                    </div>
                </div>
                <div className="container py-4">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-6 text-center text-lg-end">
                            <h4>Your Account Succssfully activated now, please </h4>
                        </div>
                        <div className="col-sm-6 text-center text-lg-start">
                            <button className="btn login text-white" type="button" style={{ backgroundColor: '#0d5c63' }}
                            onClick={()=>{
                                window.location.href="/login";
                            }}
                            >Login here...</button>
                        </div>
                    </div>
                </div>

                {/* <div class="container my-5">

                    <div class="row align-items-center">
                        <div class="col-sm-4 bg-main p-4 d-none d-lg-block">
                            <img src="assets/images/login.svg" class="img-fluid" alt="Register" />
                        </div>
                        <div class="col-sm-8 p-4">
                            <div class="form-container">
                                <h3 class="mb-3">Register</h3>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">First Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="First Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Last Name <span class="text-danger">* {lnameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Last Name" value={lname} onChange={(lname) => {
                                            setLname(lname.target.value)
                                            setLnameError(false)
                                        }} />
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Mobile Number <span class="text-danger">* {mobError ? ("Invalid Mobile") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => {
                                            setMob(mob.target.value)
                                            setMobError(false)
                                        }} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Password <span class="text-danger">* {passError ? ("Password is not strong") : ("")}</span></label>
                                        <div class="input-group">
                                            <input type="password" class="form-control" placeholder="Password" value={pass} onChange={(pass) => {
                                                setPass(pass.target.value)
                                                setPassError(false)
                                            }} />
                                            {/* <button class="btn"><i class="bi bi-eye"></i></button> 
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Password Confirmation <span
                                            class="text-danger">* {cpassError ? ("Password is not matching") : ("")}</span></label>
                                        <div class="input-group">
                                            <input type="password" class="form-control"
                                                placeholder="Password Confirmation" value={cpass} onChange={(cpass) => {
                                                    setCpass(cpass.target.value)
                                                    setCPassError(false);
                                                }} />
                                            {/* <button class="btn"><i class="bi bi-eye"></i></button> 
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Your Profession Is<span class="text-danger">*</span></label>
                                        <select class="form-select" value={prof} onChange={prof => setProf(prof.target.value)}>

                                            <option value="Profession-I">Profession-I</option>
                                            <option value="Profession-II">Profession-II</option>
                                            <option value="Profession-III">Profession-III</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Your area of practive is<span
                                            class="text-danger">* </span></label>
                                        <select class="form-select" value={pract} onChange={pract => setPract(pract.target.value)}>

                                            <option value="Area-I">Area-I</option>
                                            <option value="Area-II">Area-II</option>
                                            <option value="Area-III">Area-III</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="#" class="text-main" >Terms & Conditions</a>
                                            <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                                        </label>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <Footer />
            </React.Fragment>)

            }
        </React.Fragment>
    )
}

export default AccountActivate;