import axios from "axios";
import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "./UIComponents/LoadingSpinner";
import MetaTags from 'react-meta-tags';

function Register() {

    const [fname, setFname] = useState('');
    const [mob, setMob] = useState('');
    const [em, setEm] = useState('');
    const [nameError, setNameError] = useState(false)
    const [mobError, setMobError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [msgerror, setMsgError] = useState(false);


    function handleRegister() {

        //alert(prof);
        if (!fname) {
            toast.error("Please enter First Name");
            setNameError(true)
        }
        else if (!isEmail(em)) {
            toast.error("Please enter valid Email");
            setEmError(true);
        }
        else if (!isMob(mob)) {
            toast.error("Please enter Mobile");
            setMobError(true)
        }
        else if(!message){
            toast.error("Please enter message");
            setMsgError(true)
        }
        
        else {
            setLoading(true);
            const data = {
                name: fname ,
                email: em,
                mob: mob,
                msg:message,

            }
            axios.post('https://api.fab-g.com/api/contact',data)
            .then((res)=>{
                setLoading(false)
                // console.log(res);
                toast.success('Thank you! Contact message send successfully')

            })
            // axios.post("https://api.fab-g.com/api/register", data)
            //     .then((res) => {
            //         const data = res.data;
            //         // console.log(data);
            //         if (data.status == "success") {
            //             //alert("Registered Successfully");
            //             const id=data.data.id;
            //             localStorage.setItem('register', true);
            //             axios.get("https://api.fab-g.com/sendmail/"+id)
            //                 .then((res)=>{
            //                     const data=res.data;
            //                     // console.log(data);
            //                     // console.log('testing demo');
            //                 });
            //             window.location.href = "/login"
            //         }
            //         else {
            //             setLoading(false)
            //             toast.error(data);
            //             //alert(data.msg);
            //         }
            //     })
            //     .catch((err) => {
            //         // console.log(err);
            //         setLoading(false)
            //         toast.error(data);
            //     })
            // console.log(data);
        }


    }

    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        // setTermsConditions(e.target.checked);
    }



    return (
        <React.Fragment>
            <MetaTags>
                <title>Metal Vepar - Contact us</title>
                <meta name="description" content="Get in touch with ENT MCQ for all your FRCS (ORL-HNS) exam preparation needs. Our dedicated team is here to assist you, provide resources, and answer your inquiries. " />
                <meta property="og:title" content="Reach Out to Us for Any Questions or Collaborations" />
                <link rel="canonical" href={window.location.href}/>
                {/* <meta property="keywords" content="frcs ent section one exam, frcs mcq exam, frcs sba exam" /> */}
            </MetaTags>
            {isLoading ? <LoadingSpinner /> : (<React.Fragment>
                <ToastContainer />

                <div class="container-fluid">
                    <div class="row">
                        <Header />
                    </div>
                </div>
                

                <div class="container my-5">

                    <div class="row align-items-center">
                        <div class="col-sm-2"></div>
                        <div class="col-sm-6 p-4 d-lg-block">
                            
                                <h2 class="mb-3">Contact Us</h2>
                                <div class="row">
                                    <div class="col-sm-10 mb-3">
                                        <label class="form-label">Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    

                                </div>
                                <div class="row">
                                    <div class="col-sm-10 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div>
                                    
                                </div>
                                <div>
                                <div class="col-sm-10 mb-3">
                                        <label class="form-label">Mobile Number <span class="text-danger">* {mobError ? ("Invalid Mobile") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Mobile Number" value={mob} onChange={(mob) => {
                                            setMob(mob.target.value)
                                            setMobError(false)
                                        }} />
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-sm-10 mb-3">
                                        <label class="form-label">Message<span class="text-danger">*{msgerror ? ('Invalid Message') : ""}</span></label>
                                        <textarea className="form-control" placeholder="Message" onChange={(e)=>{
                                            setMessage(e.target.value)
                                            setMsgError(false)
                                        }}></textarea>
                                    </div>
                                    {/* <div class="col-sm-6 mb-3">
                                        <label class="form-label">Your area of practive is<span
                                            class="text-danger">* </span></label>
                                        <select class="form-select" value={pract} onChange={pract => setPract(pract.target.value)}>

                                            <option value="Area-I">Area-I</option>
                                            <option value="Area-II">Area-II</option>
                                            <option value="Area-III">Area-III</option>
                                        </select>
                                    </div> */}
                                </div>
                                
                                <button type="button" class="btn btn-main" onClick={handleRegister}>Contact</button>
                            
                        </div>
                        <div class="col-sm-4 p-4 ">
                            <img src="assets/images/5124556.jpg" class="img-fluid" alt="Register" />
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>)

            }
        </React.Fragment>
    )
}

export default Register;