import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import axios from "axios";


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement, Title,);



function PerformanceSubject() {

    const [subinfo, setSubInfo] = useState([]);
    const [ans, setAns] = useState(0)
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [total, setTotal] = useState(0);
    const [title, setTitle] = useState('')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };
    const data = {
        labels: ['Correct', 'Incorrect', 'Unanswered'],
        datasets: [
            {
                label: 'Performance ' + title,
                data: [ans, wrong, unsen],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',

                ],
                borderWidth: 1,
            },
        ],
    };

    function fetchData() {
        const uid = localStorage.getItem("info");
        const qid = localStorage.getItem('subid');
        axios.get('https://api.fab-g.com/api/fetchSubjectTimeline',{headers:{"Authorization" : `Bearer ${uid}`}})
            .then((resp) => {
                console.log(resp.data);
                setSubInfo(resp.data);
                setAns(resp.data[0].ans)
                setUnsen(resp.data[0].unsen)
                setWrong(resp.data[0].wrong)
                setTotal(resp.data[0].total);
                setTitle(resp.data[0].subname)
                // console.log(resp.data);
            })
    }

    function handleObj(obj) {
        console.log(obj);
        setAns(obj.ans)
        setUnsen(obj.unsen)
        setWrong(obj.wrong)
        setTotal(obj.total);
        setTitle(obj.subname)
    }

    useEffect(() => {
        fetchData();
    }, [])
    //ChartJS.resize(600, 200);
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    return (
        <React.Fragment>
            <Dashboard nm="Dashboard / Performance Speciality" />
            <main>
                <div class="card card-body bg-danger border-0 bg-opacity-10 text-danger mb-3" style={{ display: 'none' }}>
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="card h-100 plain-card" style={{ width: 600 }}>
                            <div class="card-header">Performance</div>
                            <div class="card-body">
                                <Line data={data} />
                            </div>

                        </div>
                        <div class="card plain-card h-100" style={{ width: 600 }}>
                            <div class="card-header">Question Status</div>
                            <div class="card-body text-center">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="card card-body bg-success bg-opacity-10 text-success border-0">
                                            <h2 class="mb-1">{ans}</h2>
                                            <p class="mb-0">Answered</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card card-body bg-primary bg-opacity-10 text-primary border-0">
                                            <h2 class="mb-1">{unsen}</h2>
                                            <p class="mb-0">Unanswered</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="card card-body bg-danger bg-opacity-10 text-danger border-0">
                                            <h2 class="mb-1">{wrong}</h2>
                                            <p class="mb-0">Incorrect</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="row gx-2">
                    <div class="col-sm-6">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Speciality</div>
                            <div class="card-body">
                                <table class="table dashboard-table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Correct</th>
                                            <th>Incorrect</th>
                                            <th>Unanswered</th>
                                            <th>No. of Q's</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            subinfo.map((obj) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                        <button type="button"
                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>{obj.subname}</button>

                                                        </td>
                                                        <td>{obj.ans}</td>
                                                        <td>{obj.wrong}</td>
                                                        <td>{obj.unsen}</td>
                                                        <td>{obj.total}</td>
                                                        {/* <td>
                                                            <button type="button"
                                                                class="btn bg-main-light text-main btn-sm" onClick={() => { handleObj(obj) }}>show</button>
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="card h-100 plain-card">
                            <div class="card-header">Performance</div>
                            <div class="card-body">
                                <Line data={data} />
                            </div>
                        </div>
                    </div>

                </div>
            </main>
            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}
export default PerformanceSubject;