import React, { useState, useEffect } from 'react';
import Dashboard from "./Dashboard";
import axios from 'axios';
import LoadingSpinner from '../UIComponents/LoadingSpinner';
function MockQuiz() {

    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    const uid = localStorage.getItem('info');
    const [quizname, setQuizname] = useState('');
    const [noquestion, setQuestion] = useState('120');
    const [mode, setMode] = useState('');
    const [qstatus, setQstatus] = useState('');
    const [difficulty, setDifficulty] = useState([])
    const [Subjects, setSubjects] = useState([]);
    const [selectSubs, setSelectSubs] = useState([]);
    const [tselectSubs, setTSelectSubs] = useState([]);
    const [secs, setSecs] = useState(135);
    const [tmMode, setTmMode] = useState('Timed');
    const [isLoading, setLoading] = useState(false);
    const [max, setMax] = useState(0);
    const [maxError, setMaxError] = useState(false)
    // const [stype, setStype] = useState('All');

    const [anscnt, setanscnt] = useState(() => {
        const data = localStorage.getItem('ans')
        if (data != null) {
            return JSON.parse(data);
        }
        return [];
    })

    function fetchSubjects() {
        const sid = localStorage.getItem('subid');
        console.log(sid);
        axios.get('https://api.fab-g.com/api/fetchQbSubjects/' + sid)
            .then((res) => {
                const data = res.data;
                setSubjects(data);
                var tempData = [];
                data.map((obj) => {
                    tempData.push(obj.name);
                })
                setSelectSubs(tempData);
                console.log(selectSubs);
            })
    }
    useEffect(() => {

        var tscode = localStorage.getItem('scode');
        if (tscode == "no") {
            window.location.href = "/no-subscription";
        }
        var val = JSON.parse(localStorage.getItem('mock'));
        setMax(val.total)
        //Addquiz()
    }, [])


    const questionstatus = (e) => {

        //const v2=e.target.value;
        //const {questionstatus} = userinforr;
        const v2 = e.target.value;
        setQstatus(v2);


    }
    const timemode = (e) => {
        // const { value, checked } = e.target;
        // const { timemode } = userinfotime;
        setTmMode(e.target.value);

        //setMode(e.target.value);

    }

    function Addquiz() {
        // alert('test');
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();

        let separator = "/";
        let dt = `${date < 10 ? `0${date}` : date }${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`;
        // let tm = parseInt(secs) * 60;
        var val = JSON.parse(localStorage.getItem('mock'));
        var sdt = ""
        // console.log(tm);
        var sbdt = []

        var tmM = "Timed";
        var tmsc = parseInt(secs) * 60;
        var id = localStorage.getItem('info');
        const subData = {
            quiz_name: "Mock-Quiz-" + dt,
            mode: "mock",
            difficulty: ['All'],
            subject: [],
            no_of_question: max,
            qstatus: 'All',
            time_mode: tmM,
            time_type: tmsc,
            total_sec: 'sec',
            qbid: "",
            scode: val.scode,
        };
        console.log(subData);
        axios.post('https://api.fab-g.com/api/quiz', subData, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                console.log(res.data);
                //alert("Subject added successfully");
                const data = res.data;
                if (data[0].status == "success") {
                    localStorage.setItem('quiz_id', data[0].qid);
                    window.location.href = '/dashboard/quiz'
                }
                //alert("Quiz added successfully");
                else {
                    alert("Quiz failed");
                }

            })
    }

    return (
        <React.Fragment>
            {isLoading ? <LoadingSpinner /> : (<React.Fragment>
                <Dashboard nm="Dashboard / Exam Mode Quiz" />
                <main>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="data-container form-container">
                                <div class="card plain-card">

                                    {/* <div class="card-header">Question Status</div>
                                    <div class="card-body">
                                        <div class="row align-items-center">
                                            <div class="col-sm-5">
                                                <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                                        autocomplete="off" value="All " onChange={questionstatus} />
                                                    <label class="btn btn-outline-primary" for="btnradio1">All </label>

                                                    

                                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio3"
                                                        autocomplete="off" value="Incorrect" onChange={questionstatus} />
                                                    <label class="btn btn-outline-primary" for="btnradio3">Incorrect</label>

                                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio4"
                                                        autocomplete="off" value="Correct" onChange={questionstatus} />
                                                    <label class="btn btn-outline-primary" for="btnradio4">Correct</label>

                                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio5"
                                                        autocomplete="off" value="Unseen" onChange={questionstatus} />
                                                    <label class="btn btn-outline-primary" for="btnradio5">Unseen</label>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div> */}
                                    <div class="card-header"><label class="form-check-label" for="flexRadioDefault3">
                                        <p class="mb-0 fw-bold">Mock Quiz</p>
                                        <small style={{ textAlign: 'justify' }}>This mock quiz is as close to the real exam as it gets. 120 randomly generated questions from the entire question bank, in timed mode (2 hours, 15 mins), will allow you to doing a real-life practice run prior to the big day - Good luck! (Please note questions will continue to be randomised from the bank each time to do a mock quiz).</small>
                                    </label></div>
                                    <div class="card-header">Number of Questions (Max 120)</div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-2">

                                                <div class="d-flex">
                                                    {/* <div class="btn-group me-3 pe-3 border-end" role="group"
                                            aria-label="Basic radio toggle button group">
                                            <input type="radio" class="btn-check" name="btnradio" id="btnradio1"
                                                autocomplete="off" />
                                            <label class="btn btn-outline-primary" for="btnradio1">10</label>

                                            <input type="radio" class="btn-check" name="btnradio"  id="btnradio2"
                                                autocomplete="off"/>
                                            <label class="btn btn-outline-primary" for="btnradio2">25</label>
                                        </div> */}
                                                    <input type="number" class="form-control" value={noquestion} onChange={(noquestion) => {
                                                        var noq = parseInt(noquestion.target.value);
                                                        setQuestion(noquestion.target.value)
                                                        if (noq <= max) {
                                                            setMaxError(false)

                                                        }
                                                        else {
                                                            setMaxError(true)
                                                        }
                                                    }} disabled />
                                                </div>
                                            </div>
                                            {maxError && (<span style={{ color: 'red' }}>Only {max} Questions are allowed</span>)}
                                        </div>
                                    </div>

                                    <div class="card-header">Timed Mode (2 hr. 15 mins.)</div>
                                    <div class="card-body">
                                        <div class="btn-group me-3 pe-3 border-end" role="group"
                                            aria-label="Basic radio toggle button group">


                                            <input type="radio" class="btn-check" name="btntimemode" id="btntimemode2" value="Timed" checked={true} onChange={timemode} autocomplete="off" />
                                            <label class="btn btn-outline-primary" for="btntimemode2">Time </label>
                                            {tmMode == "Timed" ? (
                                                <React.Fragment>
                                                    <input type="number" class="form-control" placeholder='in minutes' style={{ width: 80, }} value={secs} onChange={secs => setSecs(secs.target.value)} disabled /><label class="btn btn-outline-primary">(in Mins.)</label>
                                                </React.Fragment>

                                            ) : ""}

                                        </div>

                                    </div>



                                    <div class="card-footer border-top">

                                        <div class="d-flex justify-content-between">
                                            <button type="button" class="btn btn-secondary" onClick={()=>{
                                            window.location.href="/dashboard";
                                        }}>Cancel</button>
                                            <button type="button" class="btn btn-main" onClick={Addquiz}>Start Quiz</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {AddLibrary('/assets/js/main.js')}
            </React.Fragment>)

            }
        </React.Fragment>
    )
}
export default MockQuiz;