import React, { useState, useEffect, useRef } from 'react';
import Dashboard from "./Dashboard";
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';
import axios from 'axios';
import QuizQst from './QuizQst';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CountdownTimer from './CountdownTimer';

function QuizPage() {
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    //const qid = 1;
    let i = 0;
    const [qdata, setQdata] = useState([]);
    const [qtimer, setQTimer] = useState(0);
    const [bank, setBank] = useState([])
    const [question, setQuestion] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [cans, setCAns] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [btnname, setBtnname] = useState('Next');
    const countdownRef = useRef();
    const [isPause, setIsPause] = useState(false);
    const [qtm, setQtm] = useState(0);
    let cnt = 0;
    function fetchQustions() {
        //console.log('https://entmcq.vertextechnosys.com/api/fetchQzQs/'+qid)
        const qid = localStorage.getItem('quiz_id');
        const uid = localStorage.getItem('info');
        axios.get('https://api.fab-g.com/api/fetchQzQs/' + qid, { headers: { "Authorization": `Bearer ${uid}` } })
            .then((res) => {
                const data = res.data;
                console.log(data);
                setQdata(data[0].quiz_details)
                var bankdata = data[1].bank;
                let currentIndex = bankdata.length, randomIndex;

                // While there remain elements to shuffle.
                // while (currentIndex != 0) {

                //     // Pick a remaining element.
                //     randomIndex = Math.floor(Math.random() * currentIndex);
                //     currentIndex--;

                //     // And swap it with the current element.
                //     [bankdata[currentIndex], bankdata[randomIndex]] = [
                //         bankdata[randomIndex], bankdata[currentIndex]];
                // }
                setBank(bankdata);
                setQtm(data[0].quiz_details.total_secs * 1000);
                // console.log(data[0].quiz_details.time_type);
                localStorage.setItem('sec',""+data[0].quiz_details.total_secs)
                //console.log(bank[0].answers);
                var tcq = 0;
                data[1].bank.map((obj) => {
                    // console.log(obj)
                    if (obj.question.answer_status != "unanswered") {
                        tcq += 1
                        // setCurrentQuestion(tmp)
                    }
                })
                console.log("currentq", tcq);
                setCurrentQuestion(tcq);
                // setQuestion(bank[currentQuestion].question)
                setQuestion(data[1].bank[tcq].question);
                setAnswer(data[1].bank[tcq].answers);
                // handlepause(false)
                // console.log(question)
                //console.log(qtimer);
            })
    }

    function handlepause(isPau) {
        //setQTimer(qtimer)
        //calcTimeDelta.onPause
        if (isPau) {
            countdownRef.current.start();
            // console.log(countdownRef.current.now());
        } else {
            countdownRef.current.pause();
        }
        // setIsPause(!isPause)
        //console.log('clicked');
        // countdownRef.current.pause();
        //window.location.href = '/dashboard/my-quiz'

    }
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a complete state

        } else {
            // setQTimer(qtimer-1);
            // Render a countdown
            return (
                <span>
                    {hours}:{minutes}:{seconds}
                </span>
            );
        }
    };

    function handleComplete() {
        const resp = {
            ans: bank,
        }
        console.log(resp);
        axios.post('https://entmcq.vertextechnosys.com/api/quiz_answer', resp)
            .then((resp) => {
                //alert('Quiz ended successfully');
                console.log(resp);
                const data = resp.data;
                if (data.status = "success") {
                    window.location.href = "/Dashboard";
                }
                //window.location.href="/Dashboard";
            })
    }
    const CompleteQuiz = () => {
        // return (<span>You are good to go!</span>)
        setShow(true)
    }

    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false)
        window.location.href = '/dashboard/my-quiz'
    };
    const handleYes = () => {
        setShow(false)

        // handlepause(true)
        console.log(qtm);
        setQTimer(qtm);
    };
    const handleTick = (sec) =>{
        localStorage.setItem('sec',""+(sec/1000))
    }
    const handleShow = () => setShow(true);
    useEffect(() => {
        fetchQustions()
        console.log(show);
        // countdownRef.current.pause();
        //fetchQustions()
    }, [])
    return (
        <React.Fragment>
            <Dashboard nm="Dashboard / Quiz" />
            {qtm >0 && <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title ><p>{qdata.quiz_name}</p></Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Are you ready to start the quiz <br/><br/><br/> good luck!</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleYes}>
                        Ready to go
                    </Button>
                </Modal.Footer>
            </Modal>
                
        }
            <main>
                <div class="card plain-card border-0 rounded-0">
                    <div class="card-header border-bottom pt-0">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{qdata.quiz_name}</span>
                            <span class="small">Total Questions: {bank.length}</span>
                            <p class="mb-0 fw-bold" style={{ fontSize: 16 }}>Time - {(qtimer > 0) ? <CountdownTimer qtimer={qtimer} countdownRef={countdownRef} CompleteQuiz={CompleteQuiz} OnTick={handleTick}></CountdownTimer> : "Untimed"}</p>
                            {/* <p class="mb-0 fw-bold" style={{ fontSize: 16 }}>Time - {qtimer > 0 ? <Countdown date={Date.now() + qtimer} ref={countdownRef}><CompleteQuiz /></Countdown> : "Untimed"}</p> */}
                            {/* <button class="btn btn-light text-main btn-sm ms-4" data-bs-toggle="offcanvas"
                        data-bs-target="#QuestionListCanvas" aria-controls="QuestionListCanvas">List of Questions</button> */}
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            {bank.length > 0 && <QuizQst qdata={qdata} bank={bank} question={question} cq={currentQuestion} answer={answer} handlepause={handlepause} pause={isPause} />}
                        </div>
                    </div>
                </div>
            </main>
            {/* <!-- Modal for Check answer --> */}
            <div class="modal fade" id="CheckAnswerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="CheckAnswerModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="CheckAnswerModalLabel">Check Answer</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body small">
                            <p class="fw-bold mb-3">Your first response is used for grading in Tutor mode. You can attempt the
                                question again in this quiz, but your graded response will not change.</p>
                            <div class="form-container">
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                    <button class="btn btn-light text-main">Do not check answer</button>
                                    <button class="btn btn-main">Check Answer</button>
                                </div>

                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Do not display this message again
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for calculator --> */}
            <div class="modal fade" id="CalculatorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="CalculatorModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="CalculatorModalLabel">Calculator</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Calculator
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Lab Values --> */}
            <div class="modal fade" id="LabValuesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="LabValuesModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="LabValuesModalLabel">LabValues</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            LabValues
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Note --> */}
            <div class="modal fade" id="NoteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="NoteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0 d-block" id="NoteModalLabel">
                                Note
                            </p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-container">
                                <p class="text-muted small">Your note is automatically saved.</p>
                                <textarea class="form-control mt-3 mb-0" placeholder="Note" rows="20"></textarea>
                            </div>
                        </div>
                        <div class="modal-footer text-start">
                            <button type="button" class="btn btn-light text-main btn-sm" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal for Lab Values --> */}
            <div class="modal fade" id="GradeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                aria-labelledby="GradeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <p class="modal-title mb-0" id="GradeModalLabel">Submit for grading?</p>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body small">
                            <div class="progress mb-3" style={{ height: 7 + "px" }}>
                                <div class="progress-bar" role="progressbar" aria-label="Example 1px high" style={{ width: 25 + '%' }}
                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <strong class="mb-2">You’ve answered 1 out of 10 questions.</strong>
                            <p class="mb-0">You cannot answer or change your response to questions after submitting.</p>
                        </div>
                        <div class="modal-footer">
                            <div class="form-container d-flex justify-content-between align-items-center w-100">
                                <button class="btn btn-light text-main">Cancel</button>
                                <button class="btn btn-main">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="offcanvas offcanvas-end" tabindex="-1" data-bs-backdrop="static" id="QuestionListCanvas"
                aria-labelledby="QuestionListCanvasLabel">
                <div class="offcanvas-header">
                    <p class="offcanvas-title" id="QuestionListCanvasLabel">Question List</p>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <table class="table table-hover dashboard-table">
                        <thead>
                            <tr>
                                <th>Que. No.</th>
                                <th>Question</th>
                                <th>Subject</th>
                                <th>Status</th>
                                <th>Difficulty</th>
                                <th>Mark</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>A female patient presents with multiple trichelemmomas and hamartomatous polyps throughout
                                    the gastrointestinal tract. She is also at increased risk of breast and thyroid cancer.</td>
                                <td>Rheumatic and Systemic Disease</td>
                                <td>Answered</td>
                                <td>Moderate</td>
                                <td><i class='bx bx-flag' ></i></td>
                                <td>A female patient presents with multiple trichelemmomas and hamartomatous polyps throughout
                                    the gastrointestinal tract. </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {AddLibrary('/assets/js/main.js')}
        </React.Fragment>
    )
}
export default QuizPage;