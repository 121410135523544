import React, { useState, useEffect } from "react";

import HeaderIndex from "./HeaderIndex";
import Footer from "./Footer";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { isEmail, isName, isMob, isPasss } from "./validators/Validations";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from 'react-meta-tags';
import SpinnerLoad from "./UIComponents/SpinnerLoad";
import { Link } from "react-router-dom";

var CryptoJS = require("crypto-js");
function Home() {
    const [category, setCategory] = useState([])
    const [show, setShow] = useState(false);
    const [fname, setFname] = useState('');
    const [em, setEm] = useState('');
    const [job, setJob] = useState('');
    const [prof, setProf] = useState('United Kingdom');
    const [yr, setYr] = useState('January 2024');
    const [nameError, setNameError] = useState(false)
    const [emError, setEmError] = useState(false)
    const [termsconditions, setTermsConditions] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [isSumit,setSumit] = useState(false);

    var q_limit = 0;
    function fetchCategory() {
        axios.get('https://api.fab-g.com/api/fetchCategory')
            .then((res) => {
                const data = res.data;
                setCategory(data);
                // console.log(data);
                sessionStorage.setItem('cates', JSON.stringify(data));
            })
    }

    function handleClose() {
        setShow(!show);
    }

    function handleCheck(e) {
        //alert(e.target.checked);
        //console.log(e.target.checked);
        setTermsConditions(e.target.checked);
    }

    function handleRegister() {

        if (!fname) {
            toast.error("Please enter First Name");
            setNameError(true)
        }
        else if (!isEmail(em)) {
            toast.error("Please enter valid Email");
            setEmError(true);
        }
        else if (!job) {
            toast.error('Please enter valid Job title');
        }
        else if (!termsconditions) {
            toast.error("Please Accept Terms and Condition");
            setTermsError(true)
        }
        else if (!job) {
            toast.error("Please Accept Terms and Condition");
            setTermsError(true)
        }

        else {
            setSumit(true)
            const data = {
                name: fname,
                email: em,
                job: job,
                prof: prof,
                anti: yr,
                type: 'user'
            }
            axios.post('https://api.fab-g.com/api/launch-reg', data)
                .then((res) => {
                    const rdata = res.data;
                    if (rdata.status == 'success') {
                        // toast.success(rdata.msg);
                        toast.success("Thank you for submitting your pre-registration form for our website. We will be in touch very soon with early bird access and a discount code to use our question bank.")
                        setShow(false);

                    }
                    setSumit(false)
                })
        }
    }

    useEffect(() => {
        fetchCategory()
    }, [])
    return (
        <React.Fragment>
            <ToastContainer />
            <MetaTags>
                <title>Metal Vepar</title>
                <meta name="description" content="Get ready for your medical exams FRCS (ORL-HNS) with a wide range of medical courses and MCQ preparation materials.Elevate your medical journey with us." />
                <meta property="og:title" content="ENT Medical MCQs - All Medical Preparation websiteENT MCQ" />
                <meta property="keywords" content="Medical education, Healthcare, online practice question, frcs exam, Frcs, ent and otolaryngology, ent mcq,mcq ent, frcs question bank, frcs orl hns, FRCS (General Surgery) exams, custom quizzes, in-depth analytics, ENT FRCS exam"/>
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
            <div className="container-fluid">
                <div className="row" id="home-banner">

                    <HeaderIndex />
                    <Modal show={show} onHide={handleClose}
                        size={'lg'}
                    >

                        <Modal.Body>
                            <div style={{ position: 'absolute', paddingRight: 32, width: "100%" }}>
                                <span style={{ float: 'right', background: '#000', color: '#fff', borderRadius: 5, padding: 5, }} onClick={handleClose}>X</span>
                            </div>
                            {/* <p>Quiz Completed!</p> */}

                            <div class="col-sm-12 " style={{ background: '#188ccc' }}>
                                <img src="/assets/images/bg_lightbox.png" class="img-fluid" />
                            </div>
                            <div class="col-sm-12" style={{ padding: 10, }}>

                                <h1 style={{ fontWeight: 'bold', fontSize: 40, marginTop: 15, textAlign: 'left' }}>Exciting News - Launching Soon!</h1>
                                <p style={{ marginTop: 20, textAlign: 'justify' }}>We are very excited to announce that access to our  FRCS (ORL-HNS) exam specific, peer-reviewed and evidence based question bank will be available very soon. To receive an alert and early bird access with a special launch discount code, please complete the following pre-registration information:</p>
                                <div class="row">
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Full Name <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Full Name" value={fname} onChange={(fname) => {
                                            setFname(fname.target.value)
                                            setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Email ID <span class="text-danger">* {emError ? ("Invalid Email") : ("")}</span></label>
                                        <input type="email" class="form-control" placeholder="Email ID" value={em} onChange={(em) => {
                                            setEm(em.target.value)
                                            setEmError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Job Title <span class="text-danger">* {nameError ? ("Invalid Name") : ("")}</span></label>
                                        <input type="text" class="form-control" placeholder="Job Title" value={job} onChange={(fname) => {
                                            setJob(fname.target.value)
                                            // setNameError(false)
                                        }} />
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Region of Training<span class="text-danger">*</span></label>
                                        <select class="form-select" value={prof} onChange={(prof) => {
                                            setProf(prof.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="United Kingdom">United Kingdom</option>
                                            <option value="International">International</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <label class="form-label">Anticipated Exam Sitting<span class="text-danger">*</span></label>
                                        <select class="form-select" value={yr} onChange={(yr) => {
                                            setYr(yr.target.value)
                                            // setPract("");
                                        }}>

                                            <option value="January 2024/">January 2024</option>
                                            <option value="March 2024 (International)/">March 2024 (International)</option>
                                            <option value="July 2024">July 2024</option>
                                            <option value="Undecided">Undecided</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" onChange={handleCheck} id="flexCheckDefault" />
                                        <label class="form-check-label" for="flexCheckDefault">
                                            I agree with the <a href="/terms-conditions" class="text-main" >Terms & Conditions</a>
                                            <span class="text-danger">* {termsError ? ("Please accept terms & conditions") : ("")}</span>
                                        </label>
                                    </div>
                                </div>
                                {isSumit?<SpinnerLoad/> : <button type="button" class="btn btn-main" onClick={handleRegister}>Register</button>}
                            </div>

                        </Modal.Body>

                    </Modal>
                    <div className="col-sm-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-8">
                                    <h1 className="text-white">One Country. One Market. One App</h1>
                                    <div className="text-center text-lg-start">
                                        <Link className="btn bg-orange text-white px-5 py-3 rounded-pill" to="/about-us">About Us</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container d-none">
                    <div className="row gy-3" id="features">
                        <div className="col-lg-4">
                            <div className="card card-body h-100 shadow-lg">
                                <i className="bi bi-briefcase"></i>
                                <h4>Authentic
                                    Experience</h4>
                                <p>The only website for this exam that is designed and maintained by UK trained and based doctors. Having recently passed this examination themselves, this is the closest you will get to the actual day.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card card-body h-100 shadow-lg">
                                <i className="bi bi-shop-window"></i>
                                <h4>Mock
                                    Exams</h4>
                                <p>Option to time yourself against the clock to improve your exam technique and chances of success</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card card-body h-100 shadow-lg">
                                <i className="bi bi-journal-text"></i>
                                <h4>Open Book/Study Mode</h4>
                                <p>Attempt questions in an open book mode, to study and learn information without the pressure of submitting answers.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style={{ backgroundColor: '#aaf7ff30' }}>
                <div className="container mb-5" style={{ paddingTop: 20 }}>
                    <div className="section-heading text-center">
                        <h3>About Platform</h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-7 text-center mb-4">
                            <p style={{ textAlign: 'justify' }}>Metal Vepar is a "Digital Platform" built exclusively to connect Metal Manufacturer and traders across India. Metal Vepar is a Digital platform mostly useful for the manufacturers and traders who are having business in mostly mild Steel, Stainless Steel, Aluminum, Brass, copper & others metals .</p>
                            <p style={{ textAlign: 'justify' }}>Metal Vepar will provide information about newest products; reach to new customers, new innovative theology & Metal knowledge. Metal vepar also provides market place which will expand the business, brand and products across India. Metal vepar is a platform which will take your products to all individual traders, and also bring on your fingertip about all required service providers to your business like machine maintenance, powder coating, anodizing, annealing, pickling, chrome plating, electro polishing, cutting, bending, beveling, PVDF coating, gas suppliers & also manpower skill development programmes.</p>
                            
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <img src="/assets/images/about_bg.jpg" className="img-fluid mb-3 mb-lg-0" />
                        </div>
                        <div className="col-sm-7">
                            <p className="fw-bold">Our unique features:</p>
                            <ul className="static-list">
                                <li><p>The only website that exists specifically for this particular examination, unlike other generic multiple choice websites</p></li>
                                <li><p>Designed and maintained by MVP who have Experience in national level business experience</p></li>
                                <li><p>Option for different level-wise quiz modes</p></li>
                                <li><p>Option to time yourself and perform the real exam</p></li>
                                <li><p>Our content is regularly reviewed and updated as per the Industrial requirement and standards</p></li>
                                <li><p>Our content is regularly reviewed and updated as per the Industrial requirement and standards</p></li>
                                <li><p>Our content is regularly reviewed and updated as per the Industrial requirement and standards</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div className="container" id="categories">
                    <div className="section-heading text-center">
                        <h3>Quiz Exam Levels</h3>
                        <p>Please click on active level for begin test in this exam</p>
                    </div>
                    <div className="row gy-3 align-items-stretch justify-content-center" id="features1">
                        {

                            category.map((obj) => {
                                var id = CryptoJS.AES.encrypt("" + obj.id, 'my-secret-key@123').toString();
                                id = id.replace(/\//g, '__')
                                q_limit++;
                                return (
                                    <React.Fragment>
                                        <div className="col-sm-4 col-6 align-item-center" key={obj.id} style={{ padding: "0 5px", }}>


                                            <div className="card card-body h-100 shadow-lg p-0">
                                                <a href={"/specialist/" + id} style={{ textDecoration: 'none' }}><img src={"https://api.fab-g.com/image/" + obj.image} style={{ height: 80, marginTop: 40, }} />
                                                    <p style={{ textDecoration: 'none', paddingBottom: 20, height: 75, }}>{obj.name}</p></a>
                                                <div className="shadow-lg" style={{ padding: 10 + 'px', backgroundColor: '#DE6024' }}>
                                                    <a href="/subscribe" style={{ textDecoration: 'none' }} key={id}><p style={{ color: '#fff', fontWeight: 'bold' }}>Subscribe</p></a>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>

                                )
                            })
                        }

                    </div>
                </div>

                {/* <div className="container" id="search-box">
                    <div className="card card-body p-4">
                        <div className="row" style={{ justifyContent: "center", }}>
                            {/* <div className="col-sm-4 mb-3 mb-lg-0">
                        <select className="form-select">
                            <option value="">Select Occupation</option>
                        </select>
                    </div> *}
                            <div className="col-sm-4 mb-3 mb-lg-0">
                                <select className="form-select" onChange={(cat_id) => { setCatId(cat_id.target.value) }}>
                                    <option value="">Select Occupation</option>
                                    {

                                        category.map((obj) => {
                                            var id = CryptoJS.AES.encrypt("" + obj.id, 'my-secret-key@123').toString();
                                            id = id.replace(/\//g, '__')

                                            return (
                                                <option value={id}>{obj.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-4">
                                <button type="button" className="btn btn-main w-100" onClick={handleClick}>Find Your Question Bank</button>
                            </div>
                        </div>
                    </div>
                </div> */}



                {/* <div className="container" id="achievements">
                    <div className="section-heading text-center">
                        <h3>Achievement</h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-7 text-center mb-4">
                            <h5>Our Only Concern Is Your Education</h5>
                            <p>This website is by no means a recommendation as your only preparation method for your upcoming
                                examination.</p>
                        </div>
                    </div>
                    <div className="row align-items-center g-3">
                        <div className="col-sm-3 col-6 text-center">
                            <img src="/assets/images/group.png" />
                            <h2>353k</h2>
                            <p>Happy Students</p>
                        </div>
                        <div className="col-sm-3 col-6 text-center">
                            <img src="/assets/images/communication.png" />
                            <h2>129+</h2>
                            <p>Expert Instructors</p>
                        </div>
                        <div className="col-sm-3 col-6 text-center">
                            <img src="/assets/images/computer.png" />
                            <h2>15k+</h2>
                            <p>Popular Courses</p>
                        </div>
                        <div className="col-sm-3 col-6 text-center">
                            <img src="/assets/images/ui-design.png" />
                            <h2>152k</h2>
                            <p>Mobile Apps Users</p>
                        </div>
                    </div>
                </div> */}
            </div>
            <div class="container-fluid" style={{ backgroundColor: '#aaf7ff30' }}>
                <div className="container mb-5">
                    <div class="row align-items-center">

                        <div class="col-sm-9">
                            <p class="fw-bold">Attempt exam any time, anywhere</p>
                            <p>The pass rate of app users is proven to be higher than non-app users!</p>
                            <p style={{ textAlign: 'justify' }}>Whether you're travelling or have a spare 15 minutes whilst on the go, the free ENT MCQ app will help you to fit your examination preparation around a busy schedule. It will soon be available to download from the App Store or Google Play to access questions and media from your subscription even when offline.</p>
                            {/* <p style={{ textAlign: 'justify' }}>It soon be available to Download</p> */}
                            {/* <div class="row" style={{ padding: 10 + 'px', borderRadius: 10, }}>
                                <div class="col-lg-2 col-6">
                                    <a href="/subscribe" style={{ textDecoration: 'none' }}>


                                        
                                        <img src="/assets/images/androidplay.png" />


                                    </a>
                                </div>
                                <div class="col-lg-2 col-6">
                                    <a href="/subscribe" style={{ textDecoration: 'none' }}>


                                        
                                        <img src="/assets/images/applestore.png" />


                                    </a>
                                </div>


                            </div> */}


                        </div>
                        <div class="col-sm-3 align-item-center">
                            <center><img src="/assets/images/mobile1.png" class="img-fluid mb-3 mb-lg-0" style={{ paddingTop: 10, paddingBottom: 10, }} /></center>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid" id="subscribe">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-sm-6">
                            <h3 className="mb-3 mb-lg-0">Subscribe For Latest
                                Newsletter</h3>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Your Email" />
                                <button className="btn bg-orange text-white" type="button">Subscribe</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="container py-4">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-6 text-center text-lg-start">
                        <h4>Join the world's largest learning
                            platform today</h4>
                    </div>
                    <div className="col-sm-6 text-center text-lg-end">
                        <button className="btn bg-orange text-white" type="button">Learn More</button>
                    </div>
                </div>
            </div>*/}
            <Footer cats={category} />

        </React.Fragment >
    )
}

export default Home;