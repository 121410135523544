import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Packages() {
    const [loginError, setLoginError] = useState(false)
    const [uid, setUid] = useState(() => {
        const st = localStorage.getItem("info");
        return st || ""
    });
    const [service, setServices] = useState([]);
    const [packages, setPackage] = useState([]);
    const [isShow, setShow] = useState(false);
    const [pckg, setPckg] = useState('');
    const [drn, setDrn] = useState(null);

    const [logs, setLogs] = useState(() => {
        const st = localStorage.getItem("login");

        return st || ""
    });
    const initialOptions = {
        "client-id": "AWMmviigXSMiCI5iEh4Lb2C_p3iztV71nAr_r1mt60LHmJ9MJmJnrAVa-OxqB4g5Q7Knz_sB4zPffOFb",
        currency: "USD",
    };
    function handleLogin(scode, days) {

        if (logs == "") {
            //setLoginError(true);
            toast.error('Please login to subscribe plan');
            window.location.href = "/register";
            return false;
        }
        setPckg(scode);
        setDrn(days);
        setShow(true);
        //alert('testing');
        // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
        // var pid = bytes.toString(CryptoJS.enc.Utf8);
        // console.log(pid)
        // var pid = "";

        // if (logs == "") {
        //   //setLoginError(true);
        //   toast.error('Please login to subscribe plan');
        //   return false;
        // }

        // let newDate = new Date()
        // let date = newDate.getDate();
        // let month = newDate.getMonth() + 1;
        // let year = newDate.getFullYear();

        // let separator = "/";
        // let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;

        // let expdt = new Date(newDate.getTime() + days*24*60*60*1000)

        // date = expdt.getDate();
        // month = expdt.getMonth() + 1;
        // year = expdt.getFullYear();

        // let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
        // const sdata = {
        //   package_id: pid,
        //   sub_startdate:dt,
        //   sub_enddate:exp,
        //   sub_code: scode,
        // }
        // console.log(sdata);

        // axios.post("https://api.fab-g.com/api/subscription", sdata,{headers:{"Authorization" : `Bearer ${uid}`}})
        //   .then((res) => {
        //     const data = res.data;
        //     console.log(data);
        //     if (data.status == "success") {
        //       localStorage.setItem('subid', pid);
        //       localStorage.setItem('scode', scode);
        //       window.location.href = "/dashboard"
        //     }
        //     console.log(res);
        //   })
    }
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    function featchPackage() {
        // var bytes = CryptoJS.AES.decrypt(sid.id.replace(/__/g,'/'), 'QB@123');
        //   var pid = bytes.toString(CryptoJS.enc.Utf8);
        axios.get('https://api.fab-g.com/api/packages')
            .then((res) => {
                const data = res.data;
                console.log(data);
                setPackage(data);
            })
    }
    function featchService() {
        axios.get('https://api.fab-g.com/api/getservice')
            .then((res) => {
                const data = res.data;
                setServices(data);
            })
    }
    function AddCC(urlOfTheLibrary) {
        const script = document.createElement('link');
        script.href = urlOfTheLibrary;
        script.rel = "stylesheet";
        script.type = "text/css";
        document.head.appendChild(script);
    }
    useEffect(() => {
        featchService();
        featchPackage();
    }, [])
    return (
        <React.Fragment>
            {AddCC('/assets/css/pricingstyle.css')}
            {AddCC('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css')}
            <ToastContainer />
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>
            <div class="container pb-5" id="main-content">
                {
                    isShow ? <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons style={{ layout: "horizontal" }}
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [
                                        {
                                            amount: {
                                                value: "10.99",
                                            },
                                        },
                                    ],
                                });
                            }}
                            onApprove={(data, actions) => {
                                return actions.order.capture().then((details) => {
                                    var pid = "";

                                    if (logs == "") {
                                        //setLoginError(true);
                                        toast.error('Please login to subscribe plan');
                                        return false;
                                    }

                                    let newDate = new Date()
                                    let date = newDate.getDate();
                                    let month = newDate.getMonth() + 1;
                                    let year = newDate.getFullYear();

                                    let separator = "/";
                                    let dt = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;

                                    let expdt = new Date(newDate.getTime() + drn * 24 * 60 * 60 * 1000)

                                    date = expdt.getDate();
                                    month = expdt.getMonth() + 1;
                                    year = expdt.getFullYear();

                                    let exp = `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
                                    const sdata = {
                                        package_id: pid,
                                        sub_startdate: dt,
                                        sub_enddate: exp,
                                        sub_code: pckg,
                                    }
                                    console.log(sdata);

                                    axios.post("https://api.fab-g.com/api/subscription", sdata, { headers: { "Authorization": `Bearer ${uid}` } })
                                        .then((res) => {
                                            const data = res.data;
                                            console.log(data);
                                            if (data.status == "success") {
                                                localStorage.setItem('subid', pid);
                                                localStorage.setItem('scode', pckg);
                                                window.location.href = "/dashboard"
                                            }
                                            console.log(res);
                                        })
                                    alert(`Transaction completed by `);
                                });
                            }} />
                    </PayPalScriptProvider> :
                        <div class="pricing-table group">
                            <h1>Services Available</h1>
                            <p>Prepare For The FRCS(ORL-HNS) Section-I Examination.</p>

                            {
                                packages.map((obj) => {
                                    if (obj.package_name != "Premium") {
                                        return (
                                            <div class="block personal fl">
                                                <h2 class="title">{obj.package_name}</h2>
                                                <div class="content">
                                                    <p class="price">
                                                        <sup>£</sup>
                                                        <span>{obj.pricing}</span>
                                                        <sub>/{obj.duration} days</sub>
                                                    </p>
                                                    {/* <p class="hint">Perfect for freelancers</p> */}
                                                </div>
                                                <ul class="features">
                                                    {
                                                        service.map((objs) => {
                                                            var ids = obj.service_include;
                                                            if (ids.includes(objs.id)) {
                                                                return (
                                                                    <li class="mb-3">
                                                                        <span class="fa fa-check mr-2 text-primary"></span> {objs.service_name}</li>
                                                                )
                                                            }
                                                            else {
                                                                return (<li class="mb-3 text-muted">
                                                                    <span class="fa fa-times mr-2"></span>
                                                                    <del>{objs.service_name}</del>
                                                                </li>)
                                                            }
                                                        })
                                                    }
                                                    {/* <li><span class="fontawesome-cog"></span>1 WordPress Install</li>
                                            <li><span class="fontawesome-star"></span>25,000 visits/mo.</li>
                                            <li><span class="fontawesome-dashboard"></span>Unlimited Data Transfer</li>
                                            <li><span class="fontawesome-cloud"></span>10GB Local Storage</li> */}
                                                </ul>
                                                <div class="pt-footer">
                                                    <button type="button" onClick={() => { handleLogin(obj.package_name, obj.duration) }} className="btns" rel="nofollow noopener">
                                                        Subscribe
                                                    </button>
                                                    {/* <p>Subscribe</p> */}
                                                </div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div class="block professional fl">
                                                <h2 class="title">{obj.package_name}</h2>
                                                <div class="content">
                                                    <p class="price">
                                                        <sup>£</sup>
                                                        <span>{obj.pricing}</span>
                                                        <sub>/{obj.duration} days</sub>
                                                    </p>
                                                    {/* <p class="hint">Perfect for freelancers</p> */}
                                                </div>
                                                <ul class="features">
                                                    {
                                                        service.map((objs) => {
                                                            var ids = obj.service_include;
                                                            if (ids.includes(objs.id)) {
                                                                return (
                                                                    <li class="mb-3">
                                                                        <span class="fa fa-check mr-2 text-primary"></span> {objs.service_name}</li>
                                                                )
                                                            }
                                                            else {
                                                                return (<li class="mb-3 text-muted">
                                                                    <span class="fa fa-times mr-2"></span>
                                                                    <del>{objs.service_name}</del>
                                                                </li>)
                                                            }
                                                        })
                                                    }
                                                    {/* <li><span class="fontawesome-cog"></span>1 WordPress Install</li>
                                            <li><span class="fontawesome-star"></span>25,000 visits/mo.</li>
                                            <li><span class="fontawesome-dashboard"></span>Unlimited Data Transfer</li>
                                            <li><span class="fontawesome-cloud"></span>10GB Local Storage</li> */}
                                                </ul>
                                                <div class="pt-footer">
                                                    <button type="button" onClick={() => { handleLogin(obj.package_name, obj.duration) }} className="btns" rel="nofollow noopener">
                                                        Subscribe
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }
                            {/* <div class="block professional fl">
                        <h2 class="title">Professional</h2>
                        <div class="content">
                            <p class="price">
                                <sup>$</sup>
                                <span>99</span>
                                <sub>/mo.</sub>
                            </p>
                            <p class="hint">Suitable for startups</p>
                        </div>
                        <ul class="features">
                            <li><span class="fontawesome-cog"></span>10 WordPress Install</li>
                            <li><span class="fontawesome-star"></span>100,000 visits/mo.</li>
                            <li><span class="fontawesome-dashboard"></span>Unlimited Data Transfer</li>
                            <li><span class="fontawesome-cloud"></span>20GB Local Storage</li>
                        </ul>
                        <div class="pt-footer">
                            <p>Host My Website</p>
                        </div>
                    </div> */}

                        </div>}
            </div>
            {<Footer />}
        </React.Fragment>
    )
}
export default Packages;