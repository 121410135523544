import React, { useState, useEffect } from "react";
import Dashboard from "./Dashboard";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import axios from "axios";
var cnt = 0;
var CryptoJS = require("crypto-js");


ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    PointElement,
    LineElement, Title,);



function NoSub() {
    const [ans, setAns] = useState(0);
    const [unsen, setUnsen] = useState(0);
    const [wrong, setWrong] = useState(0);
    const [quiz, setQuiz] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const name = localStorage.getItem('name').split(" ");
    const [scode, setScode] = useState(() => {
        const st = localStorage.getItem("scode");
        return st || "trial"
    });
    function fetchData() {
        const id = localStorage.getItem('info');
        //const id=5;
        axios.get('https://api.fab-g.com/api/fetchQuiz/' + id)
            .then((res) => {
                const data = res.data;
                setAns(data.ans)
                setUnsen(data.unsen);
                setWrong(data.wrong);
                localStorage.setItem("ans", JSON.stringify(data));
                if (data.length > 0) {
                    console.log(data.ans)

                }
                var p = (parseFloat(ans) / parseFloat(parseFloat(data.total))) * 100;
                setPercentage(parseInt(p));
                //console.log(percentage);
                //console.log(data.length)
            })
    }
    function fetchQuiz() {
        const id = localStorage.getItem('info');
        const sid = localStorage.getItem('subid')
        // console.log('https://api.fab-g.com/api/fetchQuizzes/'+sid+'--'+id)
        axios.get('https://api.fab-g.com/api/fetchQuizzes/' + id)
            .then((res) => {
                const data = res.data;
                var temp = []
                cnt = 0;
                data.map((obj) => {
                    cnt++;
                    if (cnt <= 5)
                        temp.push(obj);
                })
                setQuiz(temp);
            })
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Chart.js Line Chart',
            },
        },
    };
    const data = {
        labels: ['Answered', 'Unanswered', 'Incorrect'],
        datasets: [
            {
                label: 'Performance',
                data: [ans, unsen, wrong],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',

                    'rgba(255, 206, 86, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',

                    'rgba(255, 206, 86, 1)',
                    'rgba(255, 99, 132, 1)',

                ],
                borderWidth: 1,
            },
        ],
    };

    function handleClick(val) {
        console.log(val);
        localStorage.setItem('quiz_id', val);
        window.location.href = '/dashboard/quiz'
    }
    function handleUnlock() {
        var sid = localStorage.getItem("subid");
        console.log("sid", sid);
        if (sid != null) {
            var id = CryptoJS.AES.encrypt(sid, 'QB@123').toString();
            id = id.replace(/\//g, '__')
            window.location.href = "/subscribe"
        }
        else {
            window.location.href = '/subscribe';
        }

    }
    useEffect(() => {
        // fetchData();
        // fetchQuiz();

        // const sid = localStorage.getItem('scode')
        // if(!sid){
        //     window.location.href="/no-subscription";
        // }
    }, []);
    function AddLibrary(urlOfTheLibrary) {
        const script = document.createElement('script');
        script.src = urlOfTheLibrary;
        script.async = true;
        document.body.appendChild(script);
    }
    return (
        <React.Fragment>
            <Dashboard nm={"Welcome to your Dashboard, "+ name[0]} />
            <main>
                <div class="card card-body bg-danger border-0 bg-opacity-10 text-danger mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="demo-version">
                            <p class="mb-0">You are don't have active Subscription.</p>
                            <h5 class="mb-0">Unlock Full Access</h5>
                        </div>
                        <button type="button" class="btn btn-danger btn-sm" onClick={() => { handleUnlock() }}>Subscribe Now</button>
                    </div>
                </div>



            </main>
            {AddLibrary('assets/js/main.js')}
        </React.Fragment>
    )
}
export default NoSub;